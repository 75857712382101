import React, { Component, useState, useEffect, useRef } from 'react';
import _, { isArray, isString, omit, update } from 'lodash';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, firebase, functions } from '.';
import {
  useDocumentData,
  useCollectionData,
  useCollection,
} from 'react-firebase-hooks/firestore';
import { collection, doc } from 'firebase/firestore';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  clearUser,
  setCurrentUser,
  setSingleTeamSubscription,
  setTeam,
} from './r-store/user/actions';
import { clearPlayers, setCurrentPlayers } from './r-store/player/actions';
import { clearGroups, setCurrentGroups } from './r-store/group/actions';
import { clearExams, setCurrentExams } from './r-store/exams/actions';
import {
  clearQuestions,
  setCurrentQuestions,
} from './r-store/questions/actions';
import {
  clearOrganization,
  setCurrentOrganization,
} from './r-store/organization/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { clearTeam, setTeams } from './r-store/team/actions';
import {
  clearQuizzes,
  setCurrentQuizzes,
  setCurrentScheduledQuizzes,
} from './r-store/quizzes/actions';
import { TABS, rootPathForPath } from './AppTabs';
import { setCurrentCoaches, clearCoaches } from './r-store/coach/actions';
import { clearOwners, setCurrentOwners } from './r-store/owner/actions';
import {
  clearSubscription,
  setCurrentSubscription,
} from './r-store/subscriptions/actions';
import { handleError } from './helpers/errors';
import {
  getFlatLabelForCategory,
  getTreeRouteForCategory,
} from './helpers/data';
import { clearMedia, setCurrentMedia } from './r-store/media/actions';
import { STRIPE, SUBSCRIPTION_TIER } from './config';
import { ACCESS_ACL, ACCESS_ACTIONS, ACCESS_LEVELS } from './helpers/access';
import moment from 'moment';
import {
  clearEvents,
  setEvents,
  clearEventParticipants,
  setEventParticipants,
  clearEventTeams,
  setEventTeams,
  setEventQuizzes,
  clearEventQuizzes,
} from './r-store/events/actions';
import { impersonateTier } from './r-store/impersonate/actions';

const AppReducer = ({
  userData,
  currentSubscriptionId,
  isOwner,
  isSuperAdmin,
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [organizationID, setOrganizationID] = useState('a');
  const [customerId, setCustomerId] = useState('a');

  // userUID is set from auth. Gets used by other hooks to get data
  const [userUID, setUserUID] = useState('a');
  const teamID = useSelector(
    (state) => state.DataTeamState?.teamData?._id || 'a'
  );
  const impersonate = useSelector((state) => state.ImpersonateState || {});
  const [_impersonatedUserID, _setImpersonatedUserID] = useState(null);
  const [prevTeamId, setPrevTeamId] = useState(null);
  const [playerIDs, setPlayerIDs] = useState([]);
  const [eventIDs, setEventIDs] = useState([]);
  const [eventExams, setEventExams] = useState([]);
  const [eventQuizIDs, setEventQuizIDs] = useState([]);
  const [subscriptionTier, setSubscriptionTier] = useState(
    SUBSCRIPTION_TIER.FREE.key
  );

  const [isEventPartner, setIsEventPartner] = useState(false);

  const [hiddenQuestionIDs, setHiddenQuestionIDs] = useState([]);

  const [userValue, userValueLoading, userValueError] = useDocumentData(
    doc(db, 'users', userUID),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [onboardValue, onboardLoading, onboardError] = useDocumentData(
    doc(db, 'site_settings', 'onboard'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [teamValue, teamValueLoading, teamValueError] = useCollectionData(
    firebase
      .firestore()
      .collection('teams')
      .where('organizationId', '==', organizationID),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [organizationValue, organizationValueLoading, organizationValueError] =
    useDocumentData(doc(db, 'organization', organizationID), {
      snapshotListenOptions: { includeMetadataChanges: true },
    });
  // const [usersValue, usersValueLoading, usersValueError] =
  //   _.isString(teamID) && teamID.length > 1
  //     ? useCollectionData(
  //         firebase
  //           .firestore()
  //           .collection('users')
  //           .where('teams', 'array-contains', teamID || 'a')
  //           .orderBy('name'),
  //         { snapshotListenOptions: { includeMetadataChanges: true } }
  //       )
  //     : useCollectionData(
  //         firebase
  //           .firestore()
  //           .collection('users')
  //           .where('organizations', 'array-contains', organizationID || 'a')
  //           .orderBy('name'),
  //         { snapshotListenOptions: { includeMetadataChanges: true } }
  //       );
  const [eventExamsTakenValue, eventExamsTakenLoading, eventExamsTakenError] =
    useCollection(
      firebase
        .firestore()
        .collection('event_exams_taken')
        .where(
          'event_id',
          'in',
          isArray(eventIDs) && eventIDs.length > 0 ? eventIDs : ['a']
        ),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [eventTeamsValue, eventTeamsLoading, eventTeamsError] = useCollection(
    firebase
      .firestore()
      .collection('event_teams')
      .where('event_partner_id', '==', organizationID || 'a'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [eventQuizIDsValue, eventQuizIDsLoading, eventQuizIDsError] =
    useCollection(
      firebase
        .firestore()
        .collection('event_exams')
        .where(
          'events',
          'array-contains-any',
          isArray(eventIDs) && eventIDs.length > 0 ? eventIDs : ['a']
        ),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [eventQuizzesValue, eventQuizzesLoading, eventQuizzesError] =
    useCollection(
      firebase
        .firestore()
        .collection('quizzes')
        .where(
          firebase.firestore.FieldPath.documentId(),
          'in',
          isArray(eventQuizIDs) && eventQuizIDs.length > 0
            ? eventQuizIDs
            : ['a']
        ),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [usersValue, usersValueLoading, usersValueError] = useCollection(
    firebase
      .firestore()
      .collection('users')
      .where('_organizations', 'array-contains', organizationID || 'a'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [
    teamPlayerRequestsValue,
    teamPlayerRequestsValueLoading,
    teamPlayerRequestsValueError,
  ] = useCollection(
    firebase
      .firestore()
      .collection('team_requests')
      .doc(organizationID || 'a')
      .collection('players')
      .where('status', '==', 'pending'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [
    teamCoachRequestsValue,
    teamCoachRequestsValueLoading,
    teamCoachRequestsValueError,
  ] = useCollection(
    firebase
      .firestore()
      .collection('team_requests')
      .doc(organizationID || 'a')
      .collection('coaches')
      .where('status', '==', 'pending'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [groupValue, groupValueLoading, groupValueError] = useCollection(
    firebase
      .firestore()
      .collection('groups')
      .where('organizationId', '==', organizationID || 'a')
      .orderBy('title'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [examValue, examValueLoading, examValueError] = useCollectionData(
    firebase
      .firestore()
      .collection('exams')
      .where(
        'organizationId',
        'in',
        _.isString(organizationID) && organizationID.length > 1
          ? [organizationID || 'a', 'all']
          : ['a']
      ),
    // .where('teamId', 'array-contains', [teamID || 'a', 'all', null])
    // .orderBy('title'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [examInviteValue, examInviteValueLoading, examInviteValueError] =
    useCollection(
      firebase
        .firestore()
        .collection('exam_invites')
        .where('organization_id', '==', organizationID || 'a')
        .orderBy('created', 'desc'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );
  const [examResultValue, examResultValueLoading, examResultValueError] =
    useCollection(
      firebase
        .firestore()
        .collection('exam_results')
        .doc(organizationID || 'a')
        .collection('exams')
        .orderBy('last_taken', 'desc'),
      // .where('teamId', 'array-contains', [teamID || 'a', 'all', null])
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );
  const [examsTakenValue, examsTakenValueLoading, examsTakenValueError] =
    useCollection(
      firebase
        .firestore()
        .collection('exams_taken')
        .where('organization_id', '==', organizationID || 'a'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [quizValue, quizValueLoading, quizValueError] = useCollection(
    firebase
      .firestore()
      .collection('quizzes')
      .where(
        'organizationId',
        'in',
        _.isString(organizationID) && organizationID.length > 1
          ? [organizationID || 'a', 'all']
          : ['a']
      ),
    // .orderBy('title'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [
    quizzesScheduledValue,
    quizzesScheduledValueLoading,
    quizzesScheduledValueError,
  ] = useCollection(
    firebase
      .firestore()
      .collection('exams_scheduled')
      .where(
        'organization_id',
        'in',
        _.isString(organizationID) && organizationID.length > 1
          ? [organizationID || 'a', 'all']
          : ['a']
      ),
    // .orderBy('title'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [questionValue, questionValueLoading, questionValueError] =
    useCollection(
      firebase
        .firestore()
        .collection('questions')
        .where(
          'owningOrganization',
          'in',
          _.isString(organizationID) && organizationID.length > 1
            ? [organizationID || 'a', 'all']
            : ['a']
        ),
      // .orderBy('text'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [subscriptionValue, subscriptionValueLoading, subscriptionValueError] =
    useCollection(
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId || 'a')
        .collection('subscriptions'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [customerValue, customerValueLoading, customerValueError] =
    useDocumentData(
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId || 'a'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );

  const [invoiceValue, invoiceValueLoading, invoiceValueError] =
    useCollectionData(
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId || 'a')
        .collection('subscriptions')
        .doc(currentSubscriptionId)
        .collection('invoices'),
      { snapshotListenOptions: { includeMetadataChanges: true } }
    );
  const [mediaValue, mediaValueLoading, mediaValueError] = useCollectionData(
    firebase
      .firestore()
      .collection('media')
      .doc(organizationID || 'a')
      .collection('files')
      .where('type', 'in', ['image', 'video'])
      .orderBy('created_at', 'desc'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [eventsValue, eventsValueLoading, eventsValueError] = useCollection(
    firebase
      .firestore()
      .collection('events')
      .where('eventPartnerId', '==', organizationID || 'a'),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const transformUserValues = (_uV) => {
    let uValues = _uV.map((userValue) => {
      let types = [];
      let access = userValue?.org_team;
      if (_.isObject(access) && _.isObject(access[organizationID])) {
        let tAccess = access[organizationID];
        if (_.isString(teamID) && teamID.length > 1) {
          if (_.isObject(tAccess[teamID])) {
            types = tAccess[teamID].roles;
          }
        } else {
          types = [
            ...new Set(
              Object.values(
                _.pickBy(tAccess, (value, key) => !_.startsWith(key, '_'))
              ).flatMap((obj) => obj?.roles || [])
            ),
          ];
        }
      }
      return _.omit(
        {
          ...userValue,
          ...{
            access: !!access ? access[organizationID] : {},
            type: types,
            teams: _.filter(
              Object.keys(!!access ? access?.[organizationID] || {} : {}),
              (t) => !t.startsWith('_')
            ),
            org_team: _.pickBy(
              access || {},
              (value, key) => key === organizationID || key.startsWith('_')
            ),
          },
        },
        ['h', 'user_access', 'onboard', 'organizations']
      );
    });
    return uValues;
  };

  useEffect(() => {
    let players = [];
    let coaches = [];
    let owners = [];
    let playersMap = {};
    let coachesMap = {};
    let ownersMap = {};
    let _usersValue =
      usersValue?.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }) || [];
    if (_.isArray(_usersValue) && _usersValue.length > 0) {
      _usersValue = [..._usersValue].sort((a, b) => {
        return (a.name || '')
          .toString()
          .toLowerCase()
          .trim()
          .localeCompare((b.name || '').toString().toLowerCase().trim());
      });
      if (_.isString(teamID) && teamID.length > 1) {
        let _orgUsersValue = [..._usersValue];
        _usersValue = _usersValue.filter((u) =>
          (u?._teams || []).includes(teamID)
        );
        let oUValues = transformUserValues(_orgUsersValue);
        let oPlayers = oUValues.filter((u) => u._roles.includes('player'));
        let oCoaches = oUValues.filter(
          (u) => u._roles.includes('coach') || u._roles.includes('assistant')
        );
        let oOwners = oUValues.filter((u) => u._roles.includes('owner'));
        let oPlayersMap = oPlayers.reduce((result, obj) => {
          result[obj._id] = obj;
          return result;
        }, {});
        let oCoachesMap = oCoaches.reduce((result, obj) => {
          result[obj._id] = obj;
          return result;
        }, {});
        let oOwnersMap = oOwners.reduce((result, obj) => {
          result[obj._id] = obj;
          return result;
        }, {});
        dispatch(
          setCurrentOrganization({
            players: oPlayers,
            coaches: oCoaches,
            owners: oOwners,
            playersMap: oPlayersMap,
            coachesMap: oCoachesMap,
            ownersMap: oOwnersMap,
          })
        );
      }

      let uValues = transformUserValues(_usersValue);

      players = uValues.filter((u) => u._roles.includes('player'));
      coaches = uValues.filter(
        (u) => u._roles.includes('coach') || u._roles.includes('assistant')
      );
      owners = uValues.filter((u) => u._roles.includes('owner'));
      playersMap = players.reduce((result, obj) => {
        result[obj._id] = obj;
        return result;
      }, {});
      coachesMap = coaches.reduce((result, obj) => {
        result[obj._id] = obj;
        return result;
      }, {});
      ownersMap = owners.reduce((result, obj) => {
        result[obj._id] = obj;
        return result;
      }, {});
      if (!(_.isString(teamID) && teamID.length > 1)) {
        dispatch(
          setCurrentOrganization({
            players,
            coaches,
            owners,
            playersMap,
            coachesMap,
            ownersMap,
          })
        );
      }
    } else {
      dispatch(
        setCurrentOrganization({
          players: [],
          coaches: [],
          owners: [],
          playersMap: {},
          coachesMap: {},
          ownersMap: {},
        })
      );
    }
    setPlayerIDs(Object.keys(playersMap || {}) || []);
    dispatch(setCurrentPlayers({ data: players, map: playersMap }));
    dispatch(setCurrentCoaches({ data: coaches, map: coachesMap }));
    dispatch(setCurrentOwners({ data: owners, map: ownersMap }));
  }, [usersValue, teamID, organizationID]);

  useEffect(() => {
    if (_.isString(teamID) && teamID.length > 1) {
      dispatch(
        setCurrentGroups({
          data: groupValue?.docs
            .map((doc) => {
              return { id: doc.id, ...doc.data() };
            })
            .filter((q) => q.teamId === teamID),
        })
      );
    } else {
      dispatch(
        setCurrentGroups({
          data: groupValue?.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          }),
        })
      );
    }
  }, [groupValue, teamID, organizationID]);

  useEffect(() => {
    if (_.isString(teamID) && teamID.length > 1) {
      dispatch(
        setCurrentExams({
          data: (examValue || []).filter((e) => {
            return (
              e.organizationId === 'all' ||
              e.teamId === teamID ||
              e.team_id === teamID ||
              e.teamId === 'all'
            );
          }),
        })
      );
    } else {
      dispatch(setCurrentExams({ data: examValue }));
    }
  }, [examValue, teamID, examResultValue]);

  useEffect(() => {
    let takens =
      examsTakenValue?.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) || [];
    takens = takens.filter((t) => t.archived !== true);
    let invites =
      examInviteValue?.docs.map((doc) => {
        let eData = doc.data();
        let r = { id: doc.id, ...eData };
        let taken = takens.find((r) => {
          return (
            r.quiz_id === eData.quiz_id &&
            r.exam_id === eData.exam_id &&
            r.user_id === eData.user_id
          );
        });
        if (!!taken) {
          r.taken = taken;
        }
        return r;
      }) || [];
    if (_.isString(teamID) && teamID.length > 1) {
      invites = invites.filter(
        (q) => q.team_id === teamID || _.isNil(q.team_id) || q.team_id === 'all'
      );
    }
    invites = invites.filter((i) => i.archived !== true);
    dispatch(
      setCurrentExams({
        invites,
        inviteMap: invites.reduce((acc, obj) => {
          const { quiz_id, user_id } = obj;
          if (!acc[quiz_id]) {
            acc[quiz_id] = { count: 1, users: [user_id] };
          } else {
            const existingValue = acc[quiz_id];
            if (!existingValue.users.includes(user_id)) {
              existingValue.count++;
              existingValue.users.push(user_id);
            }
          }
          return acc;
        }, {}),
      })
    );
  }, [examInviteValue, teamID, playerIDs, examsTakenValue]);

  useEffect(() => {
    // let results =
    //   examResultValue?.docs.map((doc) => {
    //     return { id: doc.id, ...doc.data() };
    //   }) || [];
    // if (_.isString(teamID) && teamID.length > 1) {
    //   dispatch(
    //     setCurrentExams({
    //       results: results.filter((q) => q.team_id === teamID),
    //     })
    //   );
    // } else {
    //   dispatch(
    //     setCurrentExams({
    //       results,
    //     })
    //   );
    // }
  }, [examResultValue, examResultValueError, examResultValueLoading]);

  useEffect(() => {
    let taken =
      examsTakenValue?.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) || [];
    taken = taken.filter((t) => t.archived !== true);
    if (_.isString(teamID) && teamID.length > 1) {
      const teamTaken = taken.filter(
        (q) => q.team_id === teamID || _.isNil(q.team_id) || q.team_id === 'all'
      );
      dispatch(
        setCurrentExams({
          taken: teamTaken,
          takenMap: teamTaken.reduce((acc, obj) => {
            const { quiz_id, user_id } = obj;
            if (!acc[quiz_id]) {
              acc[quiz_id] = { count: 1, users: [user_id] };
            } else {
              const existingValue = acc[quiz_id];
              if (!existingValue.users.includes(user_id)) {
                existingValue.count++;
                existingValue.users.push(user_id);
              }
            }
            return acc;
          }, {}),
        })
      );
    } else {
      dispatch(
        setCurrentExams({
          taken,
          takenMap: taken.reduce((acc, obj) => {
            const { quiz_id, user_id } = obj;
            if (!acc[quiz_id]) {
              acc[quiz_id] = { count: 1, users: [user_id] };
            } else {
              const existingValue = acc[quiz_id];
              if (!existingValue.users.includes(user_id)) {
                existingValue.count++;
                existingValue.users.push(user_id);
              }
            }
            return acc;
          }, {}),
        })
      );
    }
  }, [examsTakenValue, teamID]);

  const extractQuizDataFromQuizValue = (quizValue, teamID) => {
    let quizData =
      quizValue?.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) || [];
    const quizMapFields = ['title', 'average', 'most_recent', 'num_taken'];
    let quizMap = quizData.reduce((result, obj) => {
      result[obj.id] = _.pick(obj, quizMapFields);
      return result;
    }, {});
    if (_.isString(teamID) && teamID.length > 1) {
      quizData = quizData.filter(
        (q) =>
          q.organizationId === 'all' ||
          q.teamId === teamID ||
          q.team_id === teamID ||
          q.teamId === 'all'
      );
      quizMap = quizData.reduce((result, obj) => {
        result[obj.id] = _.pick(obj, quizMapFields);
        return result;
      }, {});
    }
    return { data: quizData, map: quizMap };
  };

  useEffect(() => {
    let quizData =
      quizValue?.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) || [];
    const quizMapFields = ['title', 'average', 'most_recent', 'num_taken'];
    let quizMap = quizData.reduce((result, obj) => {
      result[obj.id] = _.pick(obj, quizMapFields);
      return result;
    }, {});
    if (_.isString(teamID) && teamID.length > 1) {
      quizData = quizData.filter(
        (q) =>
          q.organizationId === 'all' ||
          q.teamId === teamID ||
          q.team_id === teamID ||
          q.teamId === 'all'
      );
      quizMap = quizData.reduce((result, obj) => {
        result[obj.id] = _.pick(obj, quizMapFields);
        return result;
      }, {});
    }
    const { data, map } = extractQuizDataFromQuizValue(quizValue, teamID);
    dispatch(
      setCurrentQuizzes({
        data,
        map,
      })
    );
  }, [quizValue, quizValueLoading, quizValueError, teamID, subscriptionTier]);

  useEffect(() => {
    // console.log({ quizzesScheduledValue });
    let quizData =
      quizzesScheduledValue?.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) || [];
    const quizMapFields = ['title', 'average', 'most_recent', 'num_taken'];

    if (_.isString(teamID) && teamID.length > 1) {
      quizData = quizData.filter(
        (q) =>
          q.organizationId === 'all' ||
          (q.team_ids || []).includes(teamID) ||
          (q.team_ids || []).includes('all')
      );
    }
    dispatch(
      setCurrentScheduledQuizzes({
        scheduled: quizData,
      })
    );
    dispatch(
      setCurrentSubscription({ numScheduledQuizzes: (quizData || []).length })
    );
  }, [quizzesScheduledValue, teamID]);

  useEffect(() => {
    let subscriptions =
      subscriptionValue?.docs.map((doc) => {
        return {
          id: doc.id,
          ..._.omit(doc.data(), ['prices', 'price', 'product']),
        };
      }) || [];
    let memberSince = subscriptions.reduce((minDate, obj) => {
      if (_.isObject(obj?.current_period_start)) {
        if (!minDate || obj?.current_period_start?.seconds < minDate) {
          return obj.current_period_start.seconds;
        }
      }
      return minDate;
    }, null);
    const currentSubscription = subscriptions.find(
      (s) => s.status === 'active'
    );
    const current = subscriptions.find((s) => s.status === 'active');
    let tier = SUBSCRIPTION_TIER.FREE.key;
    let tierString = SUBSCRIPTION_TIER.FREE.label;

    if (
      current?.id === STRIPE.subscription_override_admin ||
      current?.id === STRIPE.subscription_override_friendly
    ) {
      tier = SUBSCRIPTION_TIER.PREMIUM.key;
      tierString = SUBSCRIPTION_TIER.PREMIUM.label;
    }
    if (
      current?.isSuperAdmin === true &&
      isString(impersonate.tier) &&
      [
        SUBSCRIPTION_TIER.FREE.key,
        SUBSCRIPTION_TIER.REGULAR.key,
        SUBSCRIPTION_TIER.PREMIUM.key,
      ].includes(impersonate.tier)
    ) {
      tier = impersonate.tier;
      switch (impersonate.tier) {
        case SUBSCRIPTION_TIER.FREE.key:
          tierString = SUBSCRIPTION_TIER.FREE.label;
          break;
        case SUBSCRIPTION_TIER.REGULAR.key:
          tierString = SUBSCRIPTION_TIER.REGULAR.label;
          break;
        case SUBSCRIPTION_TIER.PREMIUM.key:
          tierString = SUBSCRIPTION_TIER.PREMIUM.label;
          break;

        default:
          break;
      }
    }

    if (isArray(current?.items) && current?.items.length > 0) {
      for (let index = 0; index < current.items.length; index++) {
        if (tier !== SUBSCRIPTION_TIER.PREMIUM.key) {
          const i = current.items[index];
          if (
            isString(i.price.id) &&
            i.price.id.length > 0 &&
            isString(STRIPE.prices[i.price.id])
          ) {
            if (STRIPE.prices[i.price.id] === SUBSCRIPTION_TIER.PREMIUM.key) {
              tier = SUBSCRIPTION_TIER.PREMIUM.key;
              tierString = SUBSCRIPTION_TIER.PREMIUM.label;
            } else if (
              STRIPE.prices[i.price.id] === SUBSCRIPTION_TIER.REGULAR.key
            ) {
              tier = SUBSCRIPTION_TIER.REGULAR.key;
              tierString = SUBSCRIPTION_TIER.REGULAR.label;
            }
          }
        }
      }
    }

    if (tier === SUBSCRIPTION_TIER.FREE.key) {
      tier = current?.items?.some(
        (i) =>
          i.plan?.product === STRIPE.products[SUBSCRIPTION_TIER.PREMIUM.key]
      )
        ? SUBSCRIPTION_TIER.PREMIUM.key
        : current?.items?.some(
            (i) =>
              i.plan?.product === STRIPE.products[SUBSCRIPTION_TIER.PREMIUM.key]
          )
        ? SUBSCRIPTION_TIER.PREMIUM.key
        : current?.items?.some(
            (i) =>
              i.plan?.product === STRIPE.products[SUBSCRIPTION_TIER.REGULAR.key]
          )
        ? SUBSCRIPTION_TIER.REGULAR.key
        : SUBSCRIPTION_TIER.FREE.key;
      tierString = current?.items?.some(
        (i) =>
          i.plan?.product === STRIPE.products[SUBSCRIPTION_TIER.PREMIUM.key]
      )
        ? SUBSCRIPTION_TIER.PREMIUM.label
        : current?.items?.some(
            (i) =>
              i.plan?.product === STRIPE.products[SUBSCRIPTION_TIER.REGULAR.key]
          )
        ? SUBSCRIPTION_TIER.REGULAR.label
        : SUBSCRIPTION_TIER.FREE.label;
    }

    const inactive = false;
    setSubscriptionTier(tier);
    dispatch(setSingleTeamSubscription(current?.quantity > 1 ? false : true));
    dispatch(
      setCurrentSubscription({
        data: subscriptions,
        current,
        memberSince,
        inactive,
        tier,
        tierString,
      })
    );
  }, [subscriptionValue, customerValue]);

  useEffect(() => {
    if (userData.isOwner) {
      const invoices = _.map(invoiceValue, (invoice) => {
        let i = _.pick(invoice, [
          'amount_due',
          'amount_paid',
          'amount_remaining',
          'attempt_count',
          'attempted',
          'charge',
          'collection_method',
          'created',
          'currency',
          'customer',
          'customer_email',
          'customer_name',
          'default_payment_method',
          'description',
          'hosted_invoice_url',
          'id',
          'invoice_pdf',
          'number',
          'paid',
          'payment_intent',
          'period_end',
          'period_start',
          'status',
          'status_transitions',
          'subscription',
          'subtotal',
          'tax',
          'total',
        ]);
        i.status_at =
          invoice?.status_transitions?.[`${invoice.status}_at`] ||
          invoice.created;
        let lines = { ...invoice.lines };
        lines.data = _.map(lines.data, (ld) =>
          _.pick(ld, [
            'amount',
            'currency',
            'description',
            'id',
            'invoice_item',
            'period',
            'proration',
            'qyantity',
          ])
        );
        i.lines = lines;
        return i;
      });
      invoices.sort((a, b) => b.status_at - a.status_at);
      dispatch(setCurrentSubscription({ invoices }));
    } else {
      dispatch(setCurrentSubscription({ invoices: null }));
    }
  }, [invoiceValue, userData]);

  useEffect(() => {
    dispatch(
      setCurrentSubscription({
        upcomingInvoice: customerValue?.upcomingInvoice,
        newQuantity: customerValue?.newQuantity,
        price_id: customerValue?.price_id,
        customer_id: customerValue?.stripeId,
      })
    );
  }, [customerValue]);

  useEffect(() => {
    if (_.isString(teamID) && teamID.length > 1) {
      dispatch(
        setCurrentMedia({
          data: (mediaValue || []).filter((m) => m.teamId === teamID),
        })
      );
    } else {
      dispatch(
        setCurrentMedia({
          data: mediaValue,
        })
      );
    }
  }, [mediaValue, teamID]);

  useEffect(() => {
    const tR = teamPlayerRequestsValue?.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    if (!(_.isArray(tR) && tR.length > 0)) {
      dispatch(setCurrentPlayers({ teamRequests: [] }));
      return;
    }

    const getUniqueRequests = (teamRequests) => {
      // Step 1: Sort the array in descending order based on created_at
      const sortedArray = _.orderBy(teamRequests, ['created_at'], ['desc']);

      // Step 2: Create an object to store unique values and their counts based on composite key (user_id + team_id)
      const uniqueObjects = {};
      const duplicateCounts = {};

      // Step 3: Iterate over the sorted array
      _.forEach(sortedArray, (obj) => {
        const compositeKey = `${obj.user_id}-${obj.team_id}`;

        // If the composite key does not exist in the uniqueObjects, add the current object
        if (!uniqueObjects[compositeKey]) {
          uniqueObjects[compositeKey] = obj;
          duplicateCounts[compositeKey] = 1;
        } else {
          duplicateCounts[compositeKey]++;
        }
      });

      // Step 4: Extract the values from uniqueObjects and attach the duplicate counts
      return _.map(uniqueObjects, (obj, compositeKey) => ({
        ...obj,
        duplicateCount: duplicateCounts[compositeKey],
      }));
    };
    if (_.isString(teamID) && teamID.length > 1) {
      let teamRequests = getUniqueRequests(
        tR.filter((t) => t.team_id === teamID)
      );
      dispatch(
        setCurrentPlayers({
          teamRequests,
        })
      );
    } else {
      let teamRequests = getUniqueRequests(tR);
      dispatch(
        setCurrentPlayers({
          teamRequests,
        })
      );
    }
  }, [teamPlayerRequestsValue, teamID]);

  useEffect(() => {
    const tR = teamCoachRequestsValue?.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    if (!(_.isArray(tR) && tR.length > 0)) {
      dispatch(setCurrentCoaches({ teamRequests: [] }));
      return;
    }

    const getUniqueRequests = (teamRequests) => {
      // Step 1: Sort the array in descending order based on created_at
      const sortedArray = _.orderBy(teamRequests, ['created_at'], ['desc']);

      // Step 2: Create an object to store unique values and their counts based on composite key (user_id + team_id)
      const uniqueObjects = {};
      const duplicateCounts = {};

      // Step 3: Iterate over the sorted array
      _.forEach(sortedArray, (obj) => {
        const compositeKey = `${obj.user_id}-${obj.team_id}`;

        // If the composite key does not exist in the uniqueObjects, add the current object
        if (!uniqueObjects[compositeKey]) {
          uniqueObjects[compositeKey] = obj;
          duplicateCounts[compositeKey] = 1;
        } else {
          duplicateCounts[compositeKey]++;
        }
      });

      // Step 4: Extract the values from uniqueObjects and attach the duplicate counts
      return _.map(uniqueObjects, (obj, compositeKey) => ({
        ...obj,
        duplicateCount: duplicateCounts[compositeKey],
      }));
    };
    if (_.isString(teamID) && teamID.length > 1) {
      let teamRequests = getUniqueRequests(
        tR.filter((t) => t.team_id === teamID)
      );
      dispatch(
        setCurrentCoaches({
          teamRequests,
        })
      );
    } else {
      let teamRequests = getUniqueRequests(tR);
      dispatch(
        setCurrentCoaches({
          teamRequests,
        })
      );
    }
  }, [teamCoachRequestsValue, teamID]);

  useEffect(() => {
    let questions =
      questionValue?.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }) || [];
    questions = questions.filter((q) => !hiddenQuestionIDs.includes(q.id));
    if (_.isString(teamID) && teamID.length > 1) {
      dispatch(
        setCurrentQuestions({
          data: (questions || []).filter(
            (q) =>
              q.owningOrganization === 'all' ||
              _.isNil(q.teams) ||
              (_.isArray(q.teams) && q.teams.includes(teamID))
          ),
        })
      );
    } else {
      dispatch(setCurrentQuestions({ data: questions }));
    }
  }, [questionValue, teamID, hiddenQuestionIDs]);

  useEffect(() => {
    let questions =
      questionValue?.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      }) || [];
    questions = questions.filter((q) => !hiddenQuestionIDs.includes(q.id));
    if (_.isString(teamID) && teamID.length > 1) {
      dispatch(
        setCurrentQuestions({
          data: (questions || []).filter(
            (q) =>
              q.owningOrganization === 'all' ||
              _.isNil(q.teams) ||
              (_.isArray(q.teams) && q.teams.includes(teamID))
          ),
        })
      );
    } else {
      dispatch(setCurrentQuestions({ data: questions }));
    }
  }, [questionValue, teamID, hiddenQuestionIDs]);

  useEffect(() => {
    if (!!user && _.isString(user.uid) && user.uid.length > 0) {
      if (isString(impersonate.userID) && impersonate.userID.length > 1) {
        setUserUID(impersonate.userID);
        _setImpersonatedUserID(impersonate.userID);
      } else if (user.uid !== userUID) {
        _setImpersonatedUserID(null);
        setUserUID(user.uid);
      } else if (isString(_impersonatedUserID)) {
        _setImpersonatedUserID(null);
        setUserUID(user.uid);
      }
    } else {
      if (!!userUID && _.isString(userUID) && userUID.length > 1) {
        logOutReducer(userUID);
      }
      setUserUID('a');
    }
  }, [user, impersonate]);

  useEffect(() => {
    if (
      _.isString(prevTeamId) &&
      prevTeamId.length > 1 &&
      _.isString(teamID) &&
      teamID.length > 1 &&
      prevTeamId !== teamID
    ) {
      try {
        let rootPath = rootPathForPath({ path: history?.location?.pathname });
        if (
          _.isFunction(history.push) &&
          rootPath !== history?.location?.pathname &&
          !history?.location?.pathname.startsWith(TABS.COACH_TEAMS.path)
        ) {
          history.push(rootPath);
        }
      } catch (error) {
        handleError({
          error,
          internal: 'AppReducer - Error reverting to root path',
        });
      }
    }
    setPrevTeamId(teamID);
  }, [teamID]);

  useEffect(() => {
    let sortedTeamValue = !!teamValue ? [...teamValue] : [];
    if (_.isArray(sortedTeamValue) && sortedTeamValue.length > 0) {
      sortedTeamValue = sortedTeamValue.sort((a, b) =>
        a.name < b.name ? -1 : 1
      );

      const updatedTeamValue = sortedTeamValue.map((team) => {
        // Create a new object with the same properties as the original object
        const updatedTeam = { ...team };
        // Add a new field 'maxxedPlayers' to the new object
        updatedTeam.maxPlayersPerTeam =
          team.maxPlayersPerTeam ||
          organizationValue?.maxPlayersPerTeam ||
          ACCESS_ACL[subscriptionTier]?.[ACCESS_ACTIONS.CREATE_PLAYER]
            .threshold;
        updatedTeam.maxCoachesPerTeam =
          team.maxCoachesPerTeam ||
          organizationValue?.maxCoachesPerTeam ||
          ACCESS_ACL[subscriptionTier]?.[ACCESS_ACTIONS.CREATE_COACH].threshold;
        updatedTeam.maxxedPlayers =
          (team.players || []).length >=
          (team.maxPlayersPerTeam ||
            organizationValue?.maxPlayersPerTeam ||
            ACCESS_ACL[subscriptionTier]?.[ACCESS_ACTIONS.CREATE_PLAYER]
              .threshold);
        updatedTeam.maxxedCoaches =
          (team.coaches || []).length >=
          (team.maxCoachesPerTeam ||
            organizationValue?.maxCoachesPerTeam ||
            ACCESS_ACL[subscriptionTier]?.[ACCESS_ACTIONS.CREATE_COACH]
              .threshold);
        return updatedTeam;
      });
      sortedTeamValue = updatedTeamValue;
    }
    const { data: quizData } = extractQuizDataFromQuizValue(quizValue, teamID);
    const { threshold, thresholdTimeInterval, thresholdTimePeriods } =
      ACCESS_ACL[subscriptionTier][ACCESS_ACTIONS.CREATE_AUTO_QUIZ];
    const thresholdTimeFrame = moment()
      .endOf(thresholdTimeInterval)
      .subtract(thresholdTimePeriods, thresholdTimeInterval);
    const quizzesInThreshold = quizData.filter(
      (q) =>
        q.created_at >
        firebase.firestore.Timestamp.fromDate(thresholdTimeFrame.toDate())
    );
    const autoQuizzesInThreshold = quizData
      .filter((q) => q.quizType === 'auto')
      .filter(
        (q) =>
          q.created_at >
          firebase.firestore.Timestamp.fromDate(thresholdTimeFrame.toDate())
      );
    dispatch(
      setCurrentSubscription({
        numAutoQuizzesThisThreshold: (autoQuizzesInThreshold || []).length,
        numQuizzesThisThreshold: (quizzesInThreshold || []).length,
      })
    );

    if (userData.isOwner) {
      dispatch(
        setTeams({
          data: !!user && !!teamValue ? sortedTeamValue : null,
          tier: subscriptionTier,
          quizData,
          thresholdTimeInterval,
          thresholdTimePeriods,
        })
      );
    } else {
      dispatch(
        setTeams({
          data:
            !!user && !!teamValue
              ? sortedTeamValue.filter((t) =>
                  userData?.data?._teams?.includes(t._id)
                )
              : null,
          tier: subscriptionTier,
          quizData,
          thresholdTimeInterval,
          thresholdTimePeriods,
        })
      );
    }
    // if (_.isArray(teamValue) && teamValue.length > 0 && _.isNil(teamID)) {
    //   teamValue.some((t) => {
    //     if (_.isString(t._id) && t._id.length > 0) {
    //       dispatch(setCurrentTeam(teamID));
    //       return true;
    //     }
    //   });
    // }
    // setTimeout(() => {}, 1000);
  }, [
    teamValue,
    userData,
    organizationValue,
    subscriptionTier,
    quizValue,
    teamID,
  ]); //, teamValueLoading, teamValueError]);

  // useEffect(() => {
  //   dispatch(setCurrentTeam(teamID));
  // }, [teamID]);

  useEffect(() => {
    dispatch(
      setCurrentOrganization({
        num_teams:
          !!user && !!organizationValue
            ? (organizationValue?.teams || []).length
            : 0,
        data:
          !!user && !!organizationValue
            ? { id: organizationValue._id, ...organizationValue }
            : null,
      })
    );
    setIsEventPartner(organizationValue?.isEventPartner === true);
    // if (
    //   _.isArray(organizationValue?.teams) &&
    //   organizationValue.teams.length === 1 &&
    //   _.isString(organizationValue.teams[0]) &&
    //   organizationValue.teams[0].length > 0
    // ) {
    //   setTeamID(organizationValue.teams[0]);
    // }
  }, [organizationValue]);

  useEffect(() => {
    dispatch(
      setCurrentSubscription({
        isEventPartner,
      })
    );
  }, [isEventPartner]);

  useEffect(() => {
    if (isEventPartner) {
      let _eventIDs = new Set();
      let _eventsValue =
        eventsValue?.docs.map((doc) => {
          _eventIDs.add(doc.id);
          return {
            ...doc.data(),
            id: doc.id,
          };
        }) || [];
      setEventIDs([..._eventIDs]);
      dispatch(setEvents(_eventsValue));
    } else {
      dispatch(clearEvents());
    }
  }, [isEventPartner, eventsValue]);

  const mergeExams = (exams) => {
    return Object.values(
      exams.reduce((acc, exam) => {
        const { player_email, ...rest } = exam;
        const participant_only_fields = [
          'player_email',
          'player_name',
          'user_id',
        ];
        const exam_taken_only_fields = [
          'id',
          'point',
          'finished',
          'device_id',
          'questions',
          'quiz_id',
          'quiz_name',
          'started',
          'event_id',
          'exam_id',
        ];
        const participant_fields = [...participant_only_fields, 'num_correct'];
        if (!acc[player_email]) {
          acc[player_email] = {
            player_email,
            _event_ids: [rest.event_id],
            exam_ids: [rest.exam_id],
            exams_taken: [omit(exam, participant_only_fields)],
            exam_taken_ids: [rest.id],
            num_exams_taken: 1,
            num_events: 1,
            ...omit(rest, exam_taken_only_fields),
          };
        } else {
          let _event_ids = new Set([...(acc[player_email]?.event_ids || [])]);
          _event_ids.add(exam.event_id);
          acc[player_email].event_ids = [..._event_ids];
          let _exam_ids = new Set([...(acc[player_email]?.exam_ids || [])]);
          _exam_ids.add(exam.exam_id);
          let _exam_taken_ids = new Set([
            ...(acc[player_email]?.exam_taken_ids || []),
          ]);
          _exam_taken_ids.add(exam.id);
          let _exams_taken = [...(acc[player_email]?.exams_taken || [])];
          _exams_taken.push(omit(exam, participant_only_fields));
          acc[player_email].exams_taken = [..._exams_taken];
          acc[player_email].exam_ids = [..._exam_ids];
          acc[player_email].exam_taken_ids = [..._exam_taken_ids];
          acc[player_email].num_exams_taken += 1;
          acc[player_email].num_events = acc[player_email].event_ids.length;
          acc[player_email].num_correct += exam.num_correct || 0;
          acc[player_email].num_questions += exam.num_questions || 0;
          acc[player_email].time_spent += exam.time_spent || 0;
          acc[player_email].totalPoints += exam.totalPoints || 0;
          // If the current exam's finished timestamp is later, update the fields
          if (exam.finished > acc[player_email].finished) {
            // acc[player_email] = { player_email, ...rest };
            Object.keys(rest).forEach((key) => {
              if (rest[key] !== null && rest[key] !== undefined) {
                acc[player_email][key] = rest[key];
              }
            });
          } else {
            Object.keys(rest).forEach((key) => {
              if (
                acc[player_email]?.[key] === null ||
                acc[player_email]?.[key] === undefined
              ) {
                acc[player_email][key] = rest[key];
              }
            });
          }
          acc[player_email].score =
            acc[player_email].num_correct / acc[player_email].num_questions;
          acc[player_email] = omit(acc[player_email], exam_taken_only_fields);
        }
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    if (isEventPartner && eventExamsTakenValue?.size > 0) {
      let _eventExamsTaken =
        eventExamsTakenValue?.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }) || [];
      let _participants = mergeExams(_eventExamsTaken);
      dispatch(setEventParticipants(_participants));
    } else {
      dispatch(clearEventParticipants());
    }
  }, [isEventPartner, eventExamsTakenValue]);

  useEffect(() => {
    if (isEventPartner && eventTeamsValue?.size > 0) {
      let _eventTeams =
        eventTeamsValue?.docs.map((doc) => {
          return omit({ ...doc.data(), id: doc.id }, ['sheetsImportId']);
        }) || [];
      dispatch(setEventTeams(_eventTeams));
    } else {
      dispatch(clearEventTeams());
    }
  }, [isEventPartner, eventTeamsValue]);

  useEffect(() => {
    let _eventQuizIDs = [
      ...new Set(
        eventQuizIDsValue?.docs.map((doc) => {
          return doc.data().quiz_id;
        })
      ),
    ];
    setEventExams(
      (eventQuizIDsValue?.docs || []).map((doc) => {
        return doc.data();
      })
    );
    setEventQuizIDs(isEventPartner ? _eventQuizIDs || [] : []);
  }, [isEventPartner, eventQuizIDsValue]);

  useEffect(() => {
    if (isEventPartner && eventQuizzesValue?.size > 0) {
      let _rawQuizzes = (eventQuizzesValue?.docs || []).map((q) => {
        let quizData = q.data();
        let event_exams = eventExams.filter((e) => {
          return e.exam_id === quizData.examId && e.quiz_id === quizData._id;
        });
        let event_exam = {};
        if (event_exams.length > 0) {
          event_exam = event_exams[0];
        }
        return omit({ ...q.data(), quiz_id: q.id, ...event_exam }, [
          'average',
          'examId',
          'most_recent',
          'num_correct',
          'num_points',
          'num_questions',
          'num_taken',
          'num_total_points',
          'organizationId',
          'teamId',
        ]);
      });
      dispatch(setEventQuizzes(_rawQuizzes));
    } else {
      dispatch(clearEventQuizzes());
    }
  }, [isEventPartner, eventQuizzesValue]);

  useEffect(() => {
    let _orgHiddenQuestionIDs = [];
    let _teamHiddenQuestionIDs = [];
    if (
      _.isArray(organizationValue?.hiddenQuestions) &&
      organizationValue.hiddenQuestions.length > 0
    ) {
      _orgHiddenQuestionIDs = Array.from(
        new Set(organizationValue.hiddenQuestions)
      );
    } else {
      _orgHiddenQuestionIDs = [];
    }
    if (_.isString(teamID) && teamID.length > 1) {
      const _currentTeam = (teamValue || []).find((t) => t._id === teamID);
      if (
        _currentTeam &&
        _.isArray(_currentTeam?.hiddenQuestions) &&
        _currentTeam.hiddenQuestions.length > 0
      ) {
        _teamHiddenQuestionIDs = Array.from(
          new Set(_currentTeam.hiddenQuestions)
        );
      } else {
        _teamHiddenQuestionIDs = [];
      }
    } else {
      _teamHiddenQuestionIDs = [];
    }
    setHiddenQuestionIDs(
      Array.from(new Set([..._orgHiddenQuestionIDs, ..._teamHiddenQuestionIDs]))
    );
  }, [organizationValue, teamValue, teamID]);

  useEffect(() => {
    // let organizationId = userValue?._roles?.includes('owner')
    //   ? userValue?.organization_id
    //   : _.isArray(userValue?.organization_id) &&
    //     userValue.organization_id.length > 0
    //   ? userValue?.organization_id[0]
    //   : null;
    if (
      _.isArray(userValue?._organizations) &&
      userValue?._organizations.length > 0
    ) {
      let organizationId = userValue?._organizations[0];
      let types = [];
      let access = userValue?.org_team;
      if (_.isObject(access) && _.isObject(access[organizationId])) {
        let tAccess = access[organizationId];
        if (_.isString(teamID) && teamID.length > 1) {
          if (_.isObject(tAccess[teamID])) {
            types = [...tAccess[teamID].roles];
            try {
              let allTypes = [
                ...new Set(
                  Object.values(tAccess)
                    .filter((a) => _.isArray(a) && !_.isEmpty(a))
                    .flatMap((obj) => obj.roles)
                ),
              ];
              if (allTypes.includes('owner')) {
                types.push('owner');
              }
            } catch {
              console.error('error');
            }
          }
        } else {
          if (_.isArray(tAccess._roles) && tAccess._roles.length > 0) {
            types = [...tAccess._roles];
          } else {
            types = [
              ...new Set(
                Object.values(
                  _.pickBy(tAccess, (value, key) => !_.startsWith(key, '_'))
                ).flatMap((obj) => obj?.roles || [])
              ),
            ];
          }
        }
        if (tAccess?._roles?.includes('owner') && !types?.includes('owner')) {
          types.push('owner');
        }
      }
      if (isEventPartner) {
        types.push(ACCESS_LEVELS.EVENT_PARTNER.key);
      }
      dispatch(
        setCurrentUser({
          isLoggedIn: !!userValue,
          isSuperAdmin: types.includes('superAdmin'),
          isOwner: types.includes('owner'),
          isHeadCoach: types.includes('coach'),
          isAsstCoach: types.includes('assistant'),
          organizationId,
          access,
          data:
            !!user && !!userValue
              ? {
                  id: user.uid,
                  ...{
                    ...userValue,
                    ...{ onboard: onboardValuesForUser(), type: types },
                  },
                }
              : null,
        })
      );

      if (_.isString(organizationId) && organizationId.length > 0) {
        setOrganizationID(organizationId);
      }
      setCustomerId(userValue?.customer_id || organizationId || 'a');
    } else {
      dispatch(
        setCurrentUser({
          isLoggedIn: !!userValue,
          isSuperAdmin: false,
          isOwner: false,
          isHeadCoach: false,
          isAsstCoach: false,
          organizationId: null,
          access: {},
          data:
            !!user && !!userValue
              ? {
                  id: user.uid,
                  ...{
                    ...userValue,
                    ...{ type: [] },
                  },
                }
              : null,
        })
      );
      setCustomerId(userValue?.customer_id || 'a');
    }
  }, [userValue, isEventPartner]);

  const onboardValuesForUser = () => {
    let onboard = { tours: userValue?.onboard?.tours };
    onboard.goals = onboardValue.goals || [];
    onboard.goals_hidden = userValue?.onboard?.goals_hidden || false;
    onboard.goals_show_override =
      userValue?.onboard?.goals_show_override || false;
    onboard.goals = onboard.goals.map((g) => {
      return {
        ...g,
        ...{ completed: false },
      };
    });

    if (
      _.isObject(userValue) &&
      _.isObject(userValue.onboard) &&
      _.isArray(userValue.onboard.goals) &&
      userValue.onboard.goals.length > 0
    ) {
      userValue.onboard.goals.forEach((g, index) => {
        if (index < onboard.goals.length) {
          if (_.isObject(onboard.goals[index])) {
            onboard.goals[index].completed = g;
          } else {
            onboard.goals[index] = { completed: g };
          }
        }
      });
    }
    let c = 0;
    (onboard.goals || []).some((g) => {
      if (g.completed === true) {
        c = c + 1;
      }
      return g.completed === false;
    });
    onboard.goalsContiguousCompletedStep = c;
    onboard.goalsCompleted = c >= (onboard.goals || []).length;
    return onboard;
  };

  useEffect(async () => {
    if (_.isString(userUID) && userUID.length > 1) {
      const qCf = functions.httpsCallable('questionCategories');
      const qC = await qCf();
      qC.data.trees = {};
      qC.data.flatLabels = {};
      ['categories', 'playerTypes', 'questionTypes'].forEach((cat) => {
        let tR = getTreeRouteForCategory({
          tree: qC.data[cat],
          treeRoutes: {},
        });
        qC.data.trees[cat] = tR;
      });
      ['playerTypes', 'questionTypes'].forEach((cat) => {
        let fL = getFlatLabelForCategory({ tree: qC.data[cat] });
        qC.data.flatLabels[cat] = fL;
      });
      dispatch(setCurrentQuestions({ categories: qC.data }));
    }
  }, [userUID]);
  const logOutReducer = (oldUserID) => {
    dispatch(clearCoaches());
    dispatch(clearEvents());
    dispatch(clearEventParticipants());
    dispatch(clearEventQuizzes());
    dispatch(clearEventTeams());
    dispatch(clearExams());
    dispatch(clearGroups());
    dispatch(clearMedia());
    dispatch(clearOrganization());
    dispatch(clearOwners());
    dispatch(clearPlayers());
    dispatch(clearQuestions());
    dispatch(clearQuizzes());
    dispatch(clearSubscription());
    dispatch(clearTeam());
    dispatch(clearUser());
  };
  return null;
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    currentSubscriptionId: state?.DataSubscriptionState?.current?.id || 'a',
  };
};
export default connect(mapStateToProps)(AppReducer);
